import axios from 'axios';
import { SelectOption } from '../../../../components/Select/types';
import { getGeneralDataToExport } from '../../../../services/api/TrackfyService';
import { parseSelectOptions } from '../../../../utils/Convert';
import { GeneralMovimentData } from '../../types/types';

export const useGeneralMoviment = () => {
  const getDataToExport = async (
    professions: SelectOption[],
    companies: SelectOption[],
    initialDate: any,
    finalDate: any
  ) => {
    const cancel = axios.CancelToken.source();
    const result = await getGeneralDataToExport(
      parseSelectOptions(professions),
      parseSelectOptions(companies),
      (new Date(initialDate.toString())).getTime(),
      (new Date(finalDate.toString())).getTime(),
      cancel.token
    );
    return result.data;
  };

  const exportGeneralDataToXlsx = (data: GeneralMovimentData[]) => {
    let date: string[] = [];
    let area: string[] = [];
    let start: string[] = [];
    let end: string[] = [];
    let profession: string[] = [];
    let collaborator: string[] = [];
    let tag: string[] = [];

    data.forEach((i) => {
      const dateParts = i.start.split('T');
      date.push(dateParts[0]);
      profession.push(i.profession);
      collaborator.push(i.collaborator);
      tag.push(i.tag);
      area.push(i.area);
      start.push(dateParts[1]);
      end.push(i.end ? i.end.split('T')[1] : '');
    });

    const formattedData: string[][] = [];
    formattedData.push(['Data', 'Área', 'Profissão', 'Colaborador', 'Tag', 'Entrada', 'Saída']);

    date.forEach((date, index) => {
      const value1 = area[index];
      const value2 = profession[index];
      const value3 = collaborator[index];
      const value4 = tag[index];
      const value5 = start[index];
      const value6 = end[index];
      formattedData.push([date, value1, value2, value3, value4, value5, value6]);
    });
    return formattedData;
  };

  return {
    exportGeneralDataToXlsx,
    getDataToExport,
  };
};
