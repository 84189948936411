import React, { useEffect, useState } from 'react';
import { Marker } from 'react-map-gl';

import { useIsMounted } from '../../hooks';
import { brokerData, LocationsProps, NotificationAlerts } from './types';

export const Routes: React.FC<LocationsProps> = ({setNotifications, setGeojson, setMapLocation, setViewState}) => {
    const isMounted = useIsMounted();
    const [coordinates, setCoordinates] = useState<brokerData[]>([]);
    const locations: React.ReactElement[] = [];

    useEffect(() => {
        const socket = new WebSocket("wss://ttnclient.trackfyapp.com/ws");

        if(isMounted.current) {
            socket.onopen = () => { console.log("Conectado ao WebSocket") }
            socket.onerror = (error) => { console.error("Erro no WebSocket:", error) }
            socket.onclose = () => { console.log("WebSocket desconectado") }

            socket.onmessage = (event) => {
                const response = JSON.parse(event.data);
        
                if(response.abw && (response.abw.lat > -30 && response.abw.lat < 0) && (response.abw.lng > -50 && response.abw.lng < 0))
                    setCoordinates((prevState) => {

                        if(JSON.stringify(prevState[prevState.length - 1]) != JSON.stringify(response)) {   
                            if(response.abw?.sos){
                                const newNotification: NotificationAlerts = { id: Date.now(), 
                                                                            tag: response.abw.tag, 
                                                                            time:response.abw.receivedAt.toString().substring(0,16),
                                                                            sos: response.abw.sos };
                                setNotifications((prev) => [newNotification, ...prev].slice(-20));
                            }

                            setViewState((prev) => {
                                if(prev.latitude === 0)
                                    return {
                                        ...prev,
                                        latitude: response.abw?.lat,
                                        longitude: response.abw?.lng,
                                        zoom: 18
                                    }

                                return prev;
                            });

                            return ([...prevState, response ].slice(-20));
                        }

                        return prevState;
                    });
            }
        }
    
        return () => { 
            socket.close() 
            isMounted.current = false;
        }
    }, ["wss://ttnclient.trackfyapp.com/ws"]);

    useEffect(() => {
        if(coordinates.length > 0) {
            setMapLocation({ latitude: coordinates[0].abw?.lat ?? 0, longitude: coordinates[0].abw?.lng ?? 0, zoom: 18});

            if (coordinates.length > 1)
                setGeojson({
                    type: "Feature",
                    geometry: {
                    type: "LineString",
                    coordinates: coordinates.map((coord) => [coord.abw?.lng, coord.abw?.lat]),
                    },
                });
        }
    }, [coordinates]);
 
    return (
        <>
            {coordinates.map((coordinate: brokerData, index: number) => {
                if(coordinate.moko) 
                    locations.push(<Marker latitude={coordinate.moko.lat} longitude={coordinate.moko.lng} color="blue">
                        <div style={{   background: "aqua",
                                        color: "blue",
                                        padding: "5px",
                                        borderRadius: "50%",
                                        textAlign: "center",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        width: "25px",
                                        height: "25px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        transform: "translate(-50%, -50%)",
                                    }}>
                            {index}
                        </div>
                    </Marker>);
                    
                if(coordinate.abw) 
                    locations.push(<Marker latitude={coordinate.abw.lat} longitude={coordinate.abw.lng}  color="#479990">
                        <div style={{   background: coordinate.abw.sos ? "#FF0000" : "aquamarine",
                                        color: coordinate.abw.sos ? "white" : "#479990",
                                        padding: "5px",
                                        borderRadius: "50%",
                                        textAlign: "center",
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        width: "20px",
                                        height: "20px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        transform: "translate(-50%, -50%)",
                                    }}>
                            {index}
                        </div>
                    </Marker>);

                return locations;
            })}
        </>
    )
}