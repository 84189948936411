import React, { useEffect, useState } from 'react';
import { Marker } from 'react-map-gl';

import { useIsMounted } from '../../hooks';
import { brokerData, LocationsProps, NotificationAlerts } from './types';

export const Locations: React.FC<LocationsProps> = ({setNotifications, setMapLocation, setViewState}) => {
    const isMounted = useIsMounted();
    const [coordinates, setCoordinates] = useState<brokerData[]>([]);
    const locations: React.ReactElement[] = [];
    
    useEffect(() => {
      const socket = new WebSocket("wss://ttnclient.trackfyapp.com/ws");
      
      if(isMounted.current){
        socket.onopen = () => { console.log("Conectado ao WebSocket"); }
        socket.onerror = (error) => { console.error("Erro no WebSocket:", error); }  
        socket.onclose = () => { console.log("WebSocket desconectado"); }

        socket.onmessage = (event) => {
          const response = JSON.parse(event.data);
        
          if(response.abw && (response.abw.lat > -30 && response.abw.lat < 0) && (response.abw.lng > -50 && response.abw.lng < 0))
            setCoordinates((prevState) => {
              if(JSON.stringify(prevState[prevState.length - 1]) != JSON.stringify(response)) {    
                if(response.abw?.sos){
                    const newNotification: NotificationAlerts = { id: Date.now(), 
                                                                  tag: response.abw.tag, 
                                                                  time:response.abw.receivedAt.toString().substring(0,16),
                                                                  sos: response.abw.sos };

                    setNotifications([newNotification]);
                }

                setViewState((prev) => {
                  if(prev.latitude === 0)
                          return {
                              ...prev,
                              latitude: response.abw?.lat,
                              longitude: response.abw?.lng,
                              zoom: 18
                          }

                  return prev;
                });

                return ([response]);
              }

              return prevState;
            });
        }
      }
      
      return () => {
        socket.close();
        isMounted.current = false;
      }
    }, ["wss://ttnclient.trackfyapp.com/ws"]);

    useEffect(() => {
      if(coordinates.length > 0)
        setMapLocation({ latitude: coordinates[0].abw?.lat ?? 0, longitude: coordinates[0].abw?.lng ?? 0, zoom: 18});
    }, [coordinates]);

    return (
      <>
        {coordinates.map((coordinate: brokerData, index: number) => {
          if(coordinate.moko) 
            locations.push(<Marker  key={index + "_" + coordinate.moko.tag + "_" + coordinate.moko.receivedAt} 
                                    latitude={coordinate.moko.lat} longitude={coordinate.moko.lng} 
                                    color={coordinate.moko.sos ? "#FF0000" :"blue"}></Marker>);
          if(coordinate.abw)
            locations.push(<Marker  key={index + "_" + coordinate.abw.tag + "_" + coordinate.abw.receivedAt}
                                    latitude={coordinate.abw.lat} longitude={coordinate.abw.lng}  
                                    color={coordinate.abw.sos ? "#FF0000" : "#479990"}></Marker>);
          return locations;
        })}
      </>
    )
}